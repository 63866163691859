var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SCard',{staticClass:"result-card",attrs:{"action":{
    text: '',
    size: '',
    icon: '',
    disabled: true,
  },"title":{
    text: ("Seu desempenho em " + (_vm.result.examName)),
    type: 3,
  }}},[_c('div',{staticClass:"result-card__details",attrs:{"slot":"body"},slot:"body"},[_c('SasInfo',{staticClass:"details__warning --mobile",attrs:{"icon":{
        name: 'info',
        color: '#78809A',
      },"label":((_vm.$t('components.examPartial.avaliableAt')) + " " + (_vm.result.date) + ".")}}),(_vm.isClassicScore)?_c('div',{staticClass:"details__result __classic-score"},[_c('div',{staticClass:"result__score"},[_c('SasInfo',{attrs:{"label":"Sua nota","value":{
            text: _vm.totalScore,
            type: 1,
          }}}),_c('h2',{staticClass:"score__base"},[_vm._v(" /10 ")])],1),_c('SasInfo',{staticClass:"result__hits",attrs:{"label":"Acertos","value":{
          text: ((_vm.result.correctAnswers) + " de " + (_vm.result.totalItems) + " questões"),
          type: 5,
        }}})],1):_c('div',{staticClass:"details__result"},[_c('div',{staticClass:"result__score"},[_c('SasInfo',{attrs:{"label":"Acertos","value":{
            text: _vm.result.correctAnswers,
            type: 1,
          }}}),_c('h2',{staticClass:"score__base"},[_vm._v(" /"+_vm._s(_vm.result.totalItems)+" questões ")])],1)]),(_vm.hasValidResultDate)?_c('SasInfo',{staticClass:"details__warning",attrs:{"icon":{
        name: 'info',
        color: '#78809A',
      },"label":((_vm.$t('components.examPartial.avaliableAt')) + " " + (_vm.result.date) + ".")}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }