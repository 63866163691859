<template>
  <SCard
    :action="{
      text: '',
      size: '',
      icon: '',
      disabled: true,
    }"
    class="result-card"
    :title="{
      text: `Seu desempenho em ${result.examName}`,
      type: 3,
    }"
  >
    <div
      slot="body"
      class="result-card__details"
    >
      <SasInfo
        class="details__warning --mobile"
        :icon="{
          name: 'info',
          color: '#78809A',
        }"
        :label="`${$t('components.examPartial.avaliableAt')} ${result.date}.`"
      />
      <div
        v-if="isClassicScore"
        class="details__result __classic-score"
      >
        <div class="result__score">
          <SasInfo
            label="Sua nota"
            :value="{
              text: totalScore,
              type: 1,
            }"
          />

          <h2 class="score__base">
            /10
          </h2>
        </div>

        <SasInfo
          class="result__hits"
          label="Acertos"
          :value="{
            text: `${result.correctAnswers} de ${result.totalItems} questões`,
            type: 5,
          }"
        />
      </div>
      <div
        v-else
        class="details__result"
      >
        <div class="result__score">
          <SasInfo
            label="Acertos"
            :value="{
              text: result.correctAnswers,
              type: 1,
            }"
          />

          <h2 class="score__base">
            /{{ result.totalItems }} questões
          </h2>
        </div>
      </div>

      <SasInfo
        v-if="hasValidResultDate"
        class="details__warning"
        :icon="{
          name: 'info',
          color: '#78809A',
        }"
        :label="`${$t('components.examPartial.avaliableAt')} ${result.date}.`"
      />
    </div>
  </SCard>
</template>

<script>
import SCard from '@/components/SCard'
import SasInfo from '@/components/global/SasInfo'

export default {
  name: 'AssessmentResultsExamPartial',

  components: {
    SCard,
    SasInfo,
  },

  props: {
    result: {
      type: Object,
      required: true,
    },
    isClassicScore: {
      type: Boolean,
      required: true,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
    product: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasValidResultDate() {
      return !Number.isNaN(Date.parse(this.result.date))
        && this.product !== 'CORRECTOR'
    },
    totalScore() {
      // eslint-disable-next-line no-magic-numbers
      return `${(Math.round(10 * this.result.classicScore) / 10).toLocaleString()}`
    },
  },
}
</script>

<style lang="sass">

.result-card
  padding: 32px 40px
  background-repeat: no-repeat, repeat
  background-position: right
  justify-content: none
  background-image: url(~@/assets/exam-illustration.svg)
  background-position-y: 48px

  +mq-l
    padding: 32px
    background-position-y: calc(100% + 48px)
    background-position-x: calc(100% + 64px)
    background-size: 320px

  +mq-s
    padding: 24px
    background-size: 240px

  +mq-xs
    padding: 16px
    background-position-y: calc(100% + 8px)
    background-size: 180px

  .card
    justify-content: none

  .details__result
    margin-top: 40px
    margin-bottom: 16px
    width: 512px

    +mq-l
      grid-gap: 24px
      width: auto
      grid-template-columns: 1fr

    +mq-xs
      margin-top: 32px

    &.__classic-score
      display: flex
      margin-right: 88px
      justify-content: space-between

    .info-right__label
      font-size: 16px

      +mq-s
        font-size: 13px

    .result__score
      display: inline-flex

      h1
        font-size: 96px
        line-height: 1
        color: $color-ink

        +mq-l
          font-size: 80px

        +mq-s
          font-size: 56px

      .score__base
        color: $color-ink-light
        align-self: flex-end

        +mq-s
          font-size: 18px

    .result__hits
      align-self: flex-start

  .details__warning
    &.--mobile
      display: none

      +mq-l
        display: grid

    +mq-l
      display: none
      margin-top: 16px
      grid-template-columns: $size-m 1fr

    .info-right__label
      font-size: 16px

      +mq-s
        font-size: 13px
.result
  display: flex
  flex-direction: row
  font-size: 1.5rem
</style>
